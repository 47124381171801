<template>
  <div>
    <div class="d-flex align-items-center mb-3">
      <div>
        <ul class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:;">Home</a></li>
          <li class="breadcrumb-item active">
            <a href="javascript:;">Cadastro Materiais</a>
          </li>
        </ul>
      </div>
    </div>

    <div class="row">
      <!-- BEGIN col-12 -->
      <div class="col-12">
        <!-- BEGIN panel -->
        <panel title="Materiais">
          <div>
            <form
              v-show="!this.$store.state.escondeCampos"
              class="row row-cols-lg-auto g-3 align-items-center"
            >
              <div class="w-30">
                <label class="form-label w-100">Código</label>
                <input
                  type="text"
                  class="form-control"
                  id="codigo"
                  placeholder="Código"
                  v-model="codigo"
                  oninput="let p=this.selectionStart;this.value=this.value.toUpperCase();this.setSelectionRange(p, p);"
                  :disabled="modo_editar"
                />
              </div>
              <div class="w-50">
                <label class="form-label w-100">Descrição</label>
                <input
                  type="text"
                  class="form-control"
                  id="descricao"
                  placeholder="Descrição"
                  v-model="descricao"
                  oninput="let p=this.selectionStart;this.value=this.value.toUpperCase();this.setSelectionRange(p, p);"
                  :disabled="modo_editar"
                />
              </div>
              <div class="w-25">
                <label class="form-label w-20">Embalagem</label>
                <v-select
                  class="w-100"
                  v-model="embalagemSelecionado"
                  :options="embalagens"
                  :reduce="(embalagens) => embalagens.codigo"
                  label="descricao"
                ></v-select>
              </div>
              <div v-show="!this.$store.state.escondeCampos" class="ms-auto">
                <a
                  v-show="!modo_editar"
                  @click="adicionar"
                  class="btn btn-success btn-rounded px-4 rounded-pill"
                  ><i class="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i>
                  Adicionar</a
                >
                <a
                  v-show="modo_editar"
                  @click="novo"
                  class="btn btn-success btn-rounded px-4 rounded-pill"
                  ><i class="fa fa-plus fa-lg me-2 ms-n2 text-success-000"></i>
                  Novo</a
                >
                <a
                  v-show="modo_editar"
                  @click="updateItem"
                  class="btn btn-blue btn-rounded px-4 rounded-pill"
                  ><i
                    class="fa fa-pencil fa-lg me-2 ms-n2 text-success-000"
                  ></i>
                  Salvar
                </a>
              </div>
            </form>

            <vue-good-table
              class="w-100"
              :columns="columns"
              ref="teste"
              :rows="materiais"
              :lineNumbers="false"
              :search-options="{
                enabled: true,
                placeholder: 'Pesquisar',
              }"
              :pagination-options="{ enabled: true, position: 'bottom' }"
              :selectOptions="{
                enabled: false,
                selectOnCheckboxOnly: false,
                selectionInfoClass: 'alert alert-info m-b-0 no-rounded-corner',
                selectionText: 'registros selecionados',
                clearSelectionText: 'Limpar',
              }"
            >
              <div slot="selected-row-actions"></div>
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'after'">
                  <button
                    @click="
                      editRow(
                        props.row.codigo,
                        props.row.descricao,
                        props.row.embalagem
                      )
                    "
                  >
                    Editar
                  </button>
                  <button @click="deleteRow(props.row.codigo)">Excluir</button>
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>
        </panel>
        <!-- END panel -->
      </div>
      <!-- END col-6 -->
    </div>
  </div>
</template>


<script>
import AppOptions from "../config/AppOptions.vue";
import api from "../services/api";

export default {
  name: "CriarLote",
  data() {
    return {
      codigo: "",
      inc: false,
      modo_editar: false,
      descricao: "",
      equipamentos: [],
      materiais: [],
      embalagens: [],
      xcodigoEmbalagem: "",
      xdescEmbalagem: "",
      embalagemSelecionado: "",
      codigoApoio: "",
      columns: [
        {
          label: "Código",
          field: "codigo",
          width: "8%",
          thClass: "text-nowrap",
          tdClass: "text-nowrap",
        },
        {
          label: "Material",
          field: "descricao",
          //width: '15%',
          thClass: "text-nowrap",
          tdClass: "text-nowrap",
        },
        {
          label: "Emb",
          field: "descricao_embalagem",
          //type: 'number',
          width: "10%",
          //tdClass: 'text-center text-nowrap',
          //thClass: 'text-center text-nowrap'
        },
        {
          label: "Ação",
          field: "after",
          width: "15%",
          hidden: this.$store.state.escondeCampos,
        },
      ],
    };
  },
  created() {
    AppOptions.appEmpty = false;

    this.carregaMateriais();
    this.carregaEmbalagem();
  },
  beforeRouteLeave(to, from, next) {
    AppOptions.appEmpty = false;
    next();
  },
  methods: {
    novo() {
      this.modo_editar = false;
      this.codigo = "";
      this.descricao = "";
      this.embalagemSelecionado = "";
    },
    editRow(codigo, descricao, embalagem) {
      //this.showAlert(id, "EDIT");

      this.codigo = codigo;
      this.descricao = descricao;
      this.embalagemSelecionado = embalagem;
      this.modo_editar = true;
    },
    deleteRow(codigo) {
      this.codigoApoio = codigo;

      this.notificacaoExcluirItem(
        "danger",
        "Atenção! Deseja excluir o material? ",
        "Excluir Itens?"
      );
    },
    showAlert(id, type) {
      this.text = `You clicked ${type} on row ID ${id}`;
    },

    voltar() {
      this.$router.push({ path: "/lotesciclos" });
    },
    async adicionar() {
      const response = await api.post("incluirmaterial", {
        empresa: this.$store.state.codigoEmpresa,
        codigo: this.codigo,
        descricao: this.descricao,
        embalagem: this.embalagemSelecionado,
      });

      console.log(response.data.status);

      if (response.status == 200) {
        //console.log(response.data.status);

        if (response.data.status == "0") {
          this.swalNotification(
            "error",
            "Código de material já cadastrado. Verifique!",
            "Atenção!"
          );
        } else {
          this.inc = true;
          this.codigo = "";
          this.descricao = "";
          this.embalagemSelecionado = "";
          this.carregaMateriais();
        }
      }
    },
    async updateItem() {
      const response = await api.post("updatematerial", {
        empresa: this.$store.state.codigoEmpresa,
        codigo: this.codigo,
        embalagem: this.embalagemSelecionado,
      });

      console.log(response.data.status);

      if (response.status == 200) {
        //console.log(response.data.status);

        this.modo_editar = false;
        this.inc = true;
        this.codigo = "";
        this.descricao = "";
        this.embalagemSelecionado = "";

        this.carregaMateriais();

        /* if (response.data.status == "0") {
          this.swalNotification(
            "error",
            "Não é possível excluir o material pois o mesmo já se encontra cadastrado em um lote!",
            "Atenção!"
          );
        } else {
          this.carregaMateriais();
        }*/
      }
    },
    async deleteItem() {
      const response = await api.post("excluirmaterial", {
        empresa: this.$store.state.codigoEmpresa,
        codigo: this.codigoApoio,
        usuario: this.$store.state.login,
      });

      console.log(response.data.status);

      if (response.status == 200) {
        //console.log(response.data.status);

        if (response.data.status == "0") {
          this.swalNotification(
            "error",
            "Não é possível excluir o material pois o mesmo já se encontra cadastrado em um lote!",
            "Atenção!"
          );
        } else {
          this.carregaMateriais();
        }
      }
    },
    async carregaMateriais() {
      const response = await api.post("materiais2", {
        empresa: this.$store.state.codigoEmpresa,
      });

      if (response.status == 200) {
        this.materiais = response.data;
      } else {
        this.materiais = [];
      }
    },
    async carregaEmbalagem() {
      const response = await api.post("embalagem", {
        empresa: this.$store.state.codigoEmpresa,
      });

      if (response.status == 200) {
        this.embalagens = response.data;
      } else {
        this.embalagens = [];
      }
    },

    notificacaoExcluirItem(swalType, msg, titulo) {
      var btnClass = swalType == "error" ? "danger" : swalType;
      btnClass = swalType == "question" ? "primary" : btnClass;
      this.$swal({
        title: titulo,
        text: msg,
        type: swalType,
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        confirmButtonClass: "btn me-5px btn-" + btnClass + "",
        cancelButtonClass: "btn btn-default",
      }).then((result) => {
        if (result.value) {
          this.deleteItem();
        } /*else {
          this.$swal("Cancelled", "Your file is still intact", "info");
        }*/
      });
    },
    swalNotification(swalType, msg, titulo) {
      var btnClass = swalType == "error" ? "danger" : swalType;
      btnClass = swalType == "question" ? "primary" : btnClass;
      this.$swal({
        title: titulo,
        text: msg,
        type: swalType,
        showCancelButton: false,
        buttonsStyling: false,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        confirmButtonClass: "btn me-5px btn-" + btnClass + "",
        cancelButtonClass: "btn btn-default",
      });
    },
  },
  watch: {
    inc: {
      deep: true,
      handler() {
        /*  if (this.inc) {
          this.carregaMateriais;
          this.inc = false;


          const response =  api.post("materiais2", {
            empresa: this.$store.state.codigoEmpresa,
      });

      if (response.status == 200) {
        this.materiais = response.data;
      } else {
        this.materiais = [];
      }


        }*/
      },
    },
  },
};
</script>