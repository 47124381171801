<template>
  <div>
    <div class="d-flex align-items-center mb-3">
      <div>
        <ul class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:;">Home</a></li>
          <li class="breadcrumb-item active">
            <a href="javascript:;">Montar Carga</a>
          </li>
        </ul>
        <h1 class="page-header mb-0">Montar Carga</h1>
        <span class="fs-5 fw-bold text-red-600 mb-0" v-show="expira">Atenção! Acesso expira em 21/07/2022! Verifique com seu gerente de contas! </span>
      </div>
      <div class="ms-auto">
        <a
          @click="gerencial"
          class="btn btn-yellow btn-rounded px-4 rounded-pill"
          ><i class="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i
          >Gerencial</a
        >
        <a
          @click="criarLote"
          class="btn btn-success btn-rounded px-4 rounded-pill"
          ><i class="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i> Criar
          Lote</a
        >
      </div>
    </div>

    <div class="row">
      <!-- BEGIN col-12 -->
      <div class="col-12">
        <!-- BEGIN panel -->
        <panel title="Lotes X Ciclos">
          <div>
            <form class="row row-cols-lg-auto g-3 align-items-center"></form>
            <vue-good-table
              class="w-100"
              :columns="columns"
              ref="teste"
              :rows="capa"
              :lineNumbers="false"
              :search-options="{
                enabled: true,
                placeholder: 'Pesquisar',
              }"
              :pagination-options="{ enabled: true, position: 'bottom' }"
              :selectOptions="{
                enabled: false,
                selectOnCheckboxOnly: false,
                selectionInfoClass: 'alert alert-info m-b-0 no-rounded-corner',
                selectionText: 'registros selecionados',
                clearSelectionText: 'Limpar',
              }"
            >
              <div slot="selected-row-actions"></div>
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'after'">
                  <button
                    @click="
                      editRow(
                        props.row.lote,
                        props.row.equipamento,
                        props.row.lote_interno
                      )
                    "
                  >
                    Editar
                  </button>
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>
        </panel>
        <!-- END panel -->
      </div>
      <!-- END col-6 -->
    </div>
  </div>
</template>



<script>
import AppOptions from "../config/AppOptions.vue";
import api from "../services/api";

export default {
  name: "LotesCiclos",
  data() {
    return {
      expira: this.$store.state.expira,
      capa: [],
      columns: [
        {
          label: "Data",
          field: "data",
          type: "date",
          dateInputFormat: "yyyy-mm-dd",
          dateOutputFormat: "dd-mm-yyyy",
          width: "10%",
          tdClass: "text-center text-nowrap",
          thClass: "text-center text-nowrap",
        },
        {
          label: "Equipamento",
          field: "equipamento",
          //width: '15%',
          thClass: "text-nowrap",
          tdClass: "text-nowrap",
          hidden: true,
        },
        {
          label: "Equipamento",
          field: "desc_equipamento",
          //width: '15%',
          thClass: "text-nowrap",
          tdClass: "text-nowrap",
        },
        {
          label: "Lote",
          field: "lote",
          //type: 'number',
          width: "10%",
          //tdClass: 'text-center text-nowrap',
          //thClass: 'text-center text-nowrap'
        },
        {
          label: "Integrador",
          field: "integrador",
          //type: 'number',
          width: "10%",
          //tdClass: 'text-center text-nowrap',
          //thClass: 'text-center text-nowrap'
        },
        {
          label: "Biologico",
          field: "biologico",
          //type: 'number',
          width: "10%",
          //tdClass: 'text-center text-nowrap',
          //thClass: 'text-center text-nowrap'
        },
        {
          label: "Programa",
          field: "descricao_programa",
          //type: 'number',
          width: "10%",
          //tdClass: 'text-center text-nowrap',
          //thClass: 'text-center text-nowrap'
        },
        {
          label: "Usuario",
          field: "usuario",
          //type: 'number',
          width: "10%",
          //tdClass: 'text-center text-nowrap',
          //thClass: 'text-center text-nowrap'
        },
        {
          label: "LoteInterno",
          field: "lote_interno",
          //type: 'number',
          width: "10%",
          //tdClass: 'text-center text-nowrap',
          //thClass: 'text-center text-nowrap'
        },
        {
          label: "Ação",
          field: "after",
          width: "10%",
          //hidden: true,
        },
      ],
    };
  },
  created() {
    AppOptions.appEmpty = false;
    this.carregaLote();
  },
  beforeRouteLeave(to, from, next) {
    AppOptions.appEmpty = false;
    next();
  },
  methods: {
    gerencial() {
      //window.open(`http://localhost:8081/?uid=${this.uid}`, '_blank')
      window.open(
        `http://data4.dataciclos.com.br/blank_inicio/?x_empresa=` +this.$store.state.codigoEmpresa.trim() ,
        "_blank"
      );
      //"https://dataciclos.com.br/data2/grid_dc_lote_capa/?x_empresa=3"
    },
    materialLotes() {
      //window.open(`http://localhost:8081/?uid=${this.uid}`, '_blank')
      window.open(
        `http://data4.dataciclos.com.br/grid_dc_materias_data/?x_empresa=` +this.$store.state.codigoEmpresa.trim() ,
        "_blank"
      );
      //"https://dataciclos.com.br/data2/grid_dc_lote_capa/?x_empresa=3"
    },
    criarLote() {
      this.$store.state.modoEdicao = false;
      this.$router.push({ path: "/criarlote2" });
    },
    async carregaLote() {
      const response = await api.post("lotecapa", {
        empresa: this.$store.state.codigoEmpresa,
      });

      console.log(response.data);

      if (response.status == 200) {
        this.capa = response.data;
      } else {
        this.capa = [];
      }
    },
    editRow(lote, equipamento, loteInterno) {
      console.log(loteInterno);
      this.$store.state.xEquipamento = equipamento;
      this.$store.state.xLote = lote;
      this.$store.state.loteInterno = loteInterno;
      this.$store.state.modoEdicao = true;
      this.$router.push({ path: "/criarlote2" });
    },
  },
};
</script>